<template>
  <div class="text-body-2 flex flex-col gap-2">
    <div>
      <span class="text-caption mr-2 rounded-lg bg-additional-1-50 p-1 text-error-100">{{ $t('on_hold') }}</span>
      <span class="text-body-2">
        <NuxtLink
          v-if="unitInfo?.unit_number"
          :to="`/properties/exclusives?query=${unitInfo?.unit_number}&project_ids=${unitInfo?.project_id}`"
          target="_blank"
          class="text-subhead-4 underline"
        >
          {{ unitInfo?.unit_number }}
        </NuxtLink>
        <span v-else class="text-subhead-4 inline-block capitalize">{{ $t('unit') }}</span>

        has been reserved by
        <span class="text-subhead-4"> {{ reservedBy }} </span>.
      </span>
    </div>
    <div class="flex flex-col gap-1">
      <template v-if="isOtherPaymentMethod">
        <span class="text-caption text-black-40">Payment method:</span>
        <p class="text-body-2">
          <span class="text-subhead-4 text-primary-100">Add payment confirmation</span> documents on a Tokens tab
        </p>
      </template>
      <template v-else>
        <span class="text-caption text-black-40">Payment link:</span>
        <p class="text-body-2">The payment link and token information are stored on the Tokens tab.</p>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ApiResponse, TimelineItem } from '@/types'
import { PAYMENT_TOKEN_CODES } from '~/constants'
import { useUiStore } from '~/store/ui'
import type { CMSInternalExclusiveUnit, UnitPaymentToken } from '~/types/properties'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const reservedBy = computed(() => props.modelValue.additional?.find((i) => i.field === 'Agent')?.value)
const unitId = computed(() => Number(props.modelValue.additional?.find((i) => i.field === 'Unit id')?.value))
const isOtherPaymentMethod = computed(
  () => props.modelValue.additional?.find((i) => i.field === 'Token Type')?.value === PAYMENT_TOKEN_CODES.MANUAL
)

const uiStore = useUiStore()

const unitInfo = ref<CMSInternalExclusiveUnit>()

const isUnitInfoLoading = ref(false)

const FAIL_MESSAGE = 'Failed to get detailed unit info in timeline, please try again later'

const getUnitInfo = async (id: UnitPaymentToken['unit_id']) => {
  try {
    isUnitInfoLoading.value = true

    const { data } = await useAPIPropertiesFetch<ApiResponse<CMSInternalExclusiveUnit>>(
      `external/properties/exclusive-units/${id}`
    )

    unitInfo.value = data
  } catch {
    uiStore.showSnackBanner(FAIL_MESSAGE, 'error')
  } finally {
    isUnitInfoLoading.value = false
  }
}

onMounted(() => {
  if (unitId.value) {
    getUnitInfo(unitId.value)
  }
})
</script>

<style scoped lang="scss"></style>
