<template>
  <div class="flex min-h-14 flex-col gap-2">
    <div v-if="isUnitInfoLoading" class="my-auto px-2">
      <UiLoader />
    </div>
    <template v-else-if="unitInfo">
      <div class="text-caption flex items-center gap-1 text-black-90">
        <UiTimer :end-date="paymentToken.expires_at" @ended="emits('ended', paymentToken.id)" />
        <span>{{ $t('on_hold') }}</span>

        <span>•</span>

        <span>{{ paymentToken.type.name }}</span>

        <div
          v-if="closable"
          class="ml-auto cursor-pointer rounded-lg transition-colors hover:bg-primary-05"
          @click="emits('close')"
        >
          <UiIcon name="small-close" class="text-primary-100" />
        </div>
      </div>
      <I18n-t keypath="reservation_lead_description" tag="div" class="text-body-2">
        <template #unit>
          <span class="text-subhead-4">{{ unitInfo.unit_number }}</span>
        </template>
      </I18n-t>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui'
import type { ApiResponse } from '~/types'
import type { CMSInternalExclusiveUnit, UnitPaymentToken } from '~/types/properties'

const props = defineProps<{
  paymentToken: UnitPaymentToken
  closable?: boolean
}>()

const emits = defineEmits<{
  (e: 'close'): void
  (e: 'ended', id: UnitPaymentToken['id']): void
}>()

const uiStore = useUiStore()

const unitInfo = ref<CMSInternalExclusiveUnit>()

const isUnitInfoLoading = ref(false)

const FAIL_MESSAGE = 'Failed to get detailed unit info, please try again later'

const getUnitInfo = async (id: UnitPaymentToken['unit_id']) => {
  try {
    isUnitInfoLoading.value = true

    const { data } = await useAPIPropertiesFetch<ApiResponse<CMSInternalExclusiveUnit>>(
      `external/properties/exclusive-units/${id}`
    )

    unitInfo.value = data
  } catch {
    uiStore.showSnackBanner(FAIL_MESSAGE, 'error')
  } finally {
    isUnitInfoLoading.value = false
  }
}

onMounted(() => {
  getUnitInfo(props.paymentToken.unit_id)
})
</script>

<style scoped></style>
