import { stringify } from 'qs'
import type { ApiResponse, Filters } from '@/types'

export const useGetPhonesList = async (query: Filters) => {
  const queryFormatted = stringify(query, { arrayFormat: 'comma', encode: false })

  const { data, error } = await useAPIFetch(`/voice-calls/locals?${queryFormatted}`)

  if (error.value) throw createError(error.value)
  return data.value
}

export const useCreatePhone = async (body: object) => {
  const { data, error } = await useAPIFetch<
    ApiResponse<{
      status: string
    }>
  >(`/voice-calls/locals`, { method: 'POST', body })

  if (error.value) throw createError(error.value)

  return data.value?.data.status
}

export const useGetVerifiedNumbers = async (query: string) => {
  const { data, error } = await useAPIFetch(`/users/phones?number=${query}`)

  if (error.value) throw createError(error.value)
  return data.value
}

export const useUpdateStatusPhone = async (id: number, body: object) =>
  await useAPIFetch(`/voice-calls/locals/${id}/status`, { method: 'PATCH', body })

export const useUpdateCanSms = async (id: number, body: object) =>
  await useAPIFetch(`/voice-calls/locals/${id}/can-sms`, { method: 'PATCH', body })

export const useDeletePhones = async (body: object) =>
  await useAPIFetch(`/voice-calls/locals`, { method: 'DELETE', body })
